import * as THREE from "three";
import { Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// obj - your object (THREE.Object3D or derived)
// point - the point of rotation (THREE.Vector3)
// axis - the axis of rotation (normalized THREE.Vector3)
// theta - radian value of rotation
// pointIsWorld - boolean indicating the point is in world coordinates (default = false)
function rotateAboutPoint(obj, point, axis, theta, pointIsWorld) {
  pointIsWorld = pointIsWorld === undefined ? false : pointIsWorld;

  if (pointIsWorld) {
    obj.parent.localToWorld(obj.position); // compensate for world coordinate
  }

  obj.position.sub(point); // remove the offset
  obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
  obj.position.add(point); // re-add the offset

  if (pointIsWorld) {
    obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
  }

  obj.rotateOnAxis(axis, theta); // rotate the OBJECT
}

const getData = async () => {
  const res = await fetch("https://backend.kaimerra.com/event");
  // const res = await fetch("http://localhost:3001/gps");
  const json = await res.json();
  return json;
};

const getAll = async () => {
  const res = await fetch("https://backend.kaimerra.com/gps");
  const json = await res.json();
  return json;
};

const cleanData = (events) => {
  //Remove data that does not conform to the visualization spec

  for (let index = 0; index < events.length; index++) {
    const caches = events[index]["caches"];
    let indexesToRemove = [];
    for (let index = 0; index < caches.length; index++) {
      if (
        caches[index]["context"] == null ||
        caches[index]["context"]["colors"] == null ||
        caches[index]["context"]["colors"].length !== 9 * 9 * 9
      ) {
        console.log("removing ", caches[index]);
        indexesToRemove.push(index);
      }
    }
    let offset = 0;
    let newResult = caches;
    for (let x = 0; x < indexesToRemove.length; x++) {
      let index = indexesToRemove[x];
      console.log("removing index ", index - offset);
      newResult.splice(index - offset, 1);
      offset++;
    }
  }
  return events;
};

const run = async () => {
  const urlParams = new URLSearchParams(window.location.search);

  const id = urlParams.get("id");
  let events;
  if (id) {
    let all = await getAll();
    let caches = [];
    for (let cache of all) {
      if (cache._id === id) caches.push(cache);
    }
    events = [
      {
        caches: caches,
        code: "test",
        name: "test",
        particapants: [],
        started: false,
        userId: "asdf",
      },
    ];
  } else {
    events = cleanData(await getData());
    console.log("events");
    console.log(events);
    console.log("after events");
  }

  const clock = new THREE.Clock();
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );

  var theCube;
  let cubes = [];
  let sceneNumber = 0;
  const nextButton = document.getElementById("next");
  const lastButton = document.getElementById("last");
  const contentLabel = document.getElementById("contentLabel");
  const contentList = document.getElementById("contentList");
  const contentAuthor = document.getElementById("contentAuthor");
  const timesFound = document.getElementById("timesFound");
  let selectedEvent = events[0];
  let selectedCache = selectedEvent["caches"][0];

  let drawScene = (cache, index) => {
    //Clear scene
    scene.clear();
    contentList.innerHTML = "";

    //Update labels
    contentLabel.innerText = "Cache " + index;
    const author = cache["playerUUID"] || "anonymous";
    const date = new Date(cache["date"]).toLocaleString("us");
    contentAuthor.innerHTML = "by " + author + "<br> at " + date;
    const contents = cache["content"];
    for (let content of contents) {
      console.log(content);
      const li = document.createElement("li");
      li.appendChild(document.createTextNode(content["name"]));
      contentList.appendChild(li);
    }
    timesFound.innerText = "found " + cache["timesFound"] + " times";

    //Draw cubes
    const res = cache["context"]["colors"];
    cubes = [];
    let i = 0;
    for (let x = 0; x < 9; x++) {
      for (let z = 0; z < 9; z++) {
        for (let y = 0; y < 9; y++) {
          if (res[i] !== 0) {
            const geometry = new THREE.BoxGeometry(1, 1, 1);
            const material = new THREE.MeshLambertMaterial({
              color: res[i],
              opacity: 1,
              transparent: true,
            });

            const cube = new THREE.Mesh(geometry, material);
            cube.position.x = x - 4;
            cube.position.y = y - 4;
            cube.position.z = z - 4;
            cubes.push(cube);

            if (x == 4 && (z == 4) & (y == 4)) {
              theCube = cube;
            }
          }
          i++;
        }
      }
    }
    for (const cube of cubes) {
      scene.add(cube);
    }

    //Replace lights
    const light = new THREE.PointLight(0xffffff, 1, 100);
    light.position.set(0, 9, 0);
    light.castShadow = true;
    scene.add(light);
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    //Update buttons
    sceneNumber = index;
    if (sceneNumber === 0) {
      lastButton.disabled = true;
    } else {
      lastButton.disabled = false;
    }
    if (sceneNumber === cacheSelector.options.length - 1) {
      nextButton.disabled = true;
    } else {
      nextButton.disabled = false;
    }
  };

  camera.position.z = 5;

  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);

  let rotate = true;
  renderer.domElement.addEventListener("mousedown", () => {
    rotate = false;
  });
  renderer.domElement.addEventListener("mouseup", () => {
    rotate = true;
  });

  //Set up input
  let cacheSelector = document.getElementById("caches");
  cacheSelector.addEventListener("change", function () {
    selectedCache = selectedEvent["caches"][cacheSelector.value];
    drawScene(selectedCache, cacheSelector.value);
  });

  const eventSelector = document.getElementById("events");

  //Update caches dropdown
  for (let index = 0; index < events.length; index++) {
    const event = events[index];
    let eventOption = document.createElement("option");
    eventOption.text = event.name;
    eventOption.value = event["_id"];
    eventSelector.add(eventOption);
  }

  eventSelector.addEventListener("change", function () {
    selectedEvent = events[eventSelector.selectedIndex];
    filterCaches(cacheSelector, eventSelector.selectedIndex);
    selectedCache = selectedEvent["caches"][0];
    cacheSelector.value = cacheSelector.options[0].value;
    drawScene(selectedCache, cacheSelector.value);
    if (filterShines) {
      filterShines();
    }
  });

  filterCaches(cacheSelector, 0);
  function filterCaches(cacheSelector, eventIndex) {
    [...cacheSelector.options].forEach((option) => {
      option.remove();
      option.selected = false;
    });
    const caches = events[eventIndex]["caches"];
    console.log(
      caches.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      })
    );
    console.log(caches);
    for (let index = 0; index < caches.length; index++) {
      var cache = caches[index];
      var option = document.createElement("option");
      option.text = new Date(cache["date"]).toLocaleString("us");
      option.value = index;
      cacheSelector.add(option);
    }
  }

  drawScene(selectedCache, sceneNumber);
  let shineFilter = false;
  let updateScene = false;
  let firstRelevantScene = false;

  function filterShines() {
    let options = cacheSelector.options;
    for (let index = 0; index < options.length; index++) {
      const option = options[index];
      const cache = selectedEvent["caches"][index];
      const date = new Date(cache.date);
      console.log(date, date.getDate(), date.getMonth(), date.getFullYear());
      option.hidden =
        shineFilter &&
        (date.getDate() !== 8 ||
          date.getMonth() !== 6 ||
          date.getFullYear() !== 2022);
      if (option.hidden === true && sceneNumber === index) {
        updateScene = true;
      } else if (option.hidden === false && firstRelevantScene === false) {
        firstRelevantScene = index;
      }
    }
    if (updateScene) {
      selectedCache = selectedEvent["caches"][firstRelevantScene];
      drawScene(selectedCache, firstRelevantScene);
      cacheSelector.selectedIndex = sceneNumber;
    }
  }
  let opacityButton = document.getElementById("opacity");
  let opacityToggle = false;
  opacityButton.addEventListener("click", function () {
    opacityToggle = !opacityToggle;
    if (!opacityToggle) {
      for (let cube of cubes) {
        cube.material.opacity = 1;
      }
    }
  });

  let contentsButton = document.getElementById("contents");
  let contentsToggle = true;
  contentsButton.addEventListener("click", function () {
    contentsToggle = !contentsToggle;
    if (contentsToggle) {
      contentsButton.innerText = "Show contents 📖";
      contentList.hidden = true;
    } else {
      contentsButton.innerText = "Hide contents 📕";
      contentList.hidden = false;
    }
  });

  nextButton.addEventListener("click", function () {
    const nextScene = parseInt(sceneNumber) + 1;
    cacheSelector.selectedIndex = nextScene;
    selectedCache = selectedEvent["caches"][nextScene];
    drawScene(selectedCache, nextScene);
  });
  lastButton.addEventListener("click", function () {
    const nextScene = parseInt(sceneNumber) - 1;
    cacheSelector.selectedIndex = nextScene;
    selectedCache = selectedEvent["caches"][nextScene];
    drawScene(selectedCache, nextScene);
  });

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.target.set(0, 0, 0);
  camera.position.set(8, 12, 0);

  renderer.setClearColor(0x0000ff);

  var reset = 255;

  function updateColor(elapsed) {
    let r = 255;
    let g = 255;
    let b = (elapsed * 150) % reset;

    if (theCube != null) {
      theCube.material = new THREE.MeshLambertMaterial({
        color: (r << 16) | (g << 8) | b,
        opacity: 1,
      });
    }
  }

  function animate() {
    requestAnimationFrame(animate);

    controls.update();

    const elapsed = clock.getElapsedTime();
    updateColor(elapsed);
    if (rotate) {
      rotateAboutPoint(
        camera,
        new Vector3(0, 0, 0),
        new Vector3(0, 1, 0),
        Math.PI / 180
      );
    }

    if (opacityToggle)
      for (let index in cubes) {
        let cubePosition = cubes[index].position.clone().project(camera);
        const targetPosition = theCube.position.clone().project(camera);

        let opacity = 1;
        if (cubePosition.z < targetPosition.z) {
          opacity = cubePosition.z * 0.1;
        }
        cubes[index].material.opacity = Math.min(opacity, 1);
      }

    renderer.render(scene, camera);
  }
  animate();
};

run();
